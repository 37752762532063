import RecordInfoModal from 'o365.vue.components.RecordInfo.vue';
import SingletonApp from 'o365.vue.modules.SingletonApp.ts';
//import { reactive, markRaw } from 'vue';

export function showRecordInfoForDataObject(dataObject: any) {
    if (dataObject == null) { return; }
    const recordInfoService = RecordInfoService.getRecordInfoService({dataObject: dataObject});
}

export function showRecordInfoForRow(row: any) {
    if (row == null) { return; }
    const recordInfoService = RecordInfoService.getRecordInfoService({row: row});
}

export class RecordInfoService {
    static #isInternalConstructing: boolean;
    private static _service: RecordInfoService;

    private _singletonApp: SingletonApp
    private _initialized = false;

    static getRecordInfoService(rootProps) {
        if (!RecordInfoService._service) {
            RecordInfoService.#isInternalConstructing = true;
            RecordInfoService._service = new RecordInfoService(rootProps);
            RecordInfoService._service.initialize();
            RecordInfoService.#isInternalConstructing = false;
        }
        return RecordInfoService._service;
    }

    constructor(rootProps: any) {
        if (!RecordInfoService.#isInternalConstructing) {
            throw new TypeError('RecordInfoService is not constructable, use RecordInfoService.getRecordInfoService()');
        }
        //this.state = reactive(new ToastServiceState());
        this._singletonApp = SingletonApp.getOrCreateApp({
            id: 'o365-record-info-app',
            appComponent: RecordInfoModal,
            rootProps: rootProps,
            provides: {
                'RecordInfoService': RecordInfoService,
            },
        });
    }

    private initialize() {
        if (this._initialized) { return; }
        this._initialized = true;
        this._singletonApp.mount();
    }
}